import React from "react";
import './../../Css/main.css';

export default function Page404(  ){
    return(
        <div>
            404 Not Found
        </div>
    )
}
