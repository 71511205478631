export const test_data_filters = {
    "category": [
        {
            "Music": [
                {
                    "id": "ROCK",
                    "label": "Rock",
                },
                {
                    "id": "POP1",
                    "label": "Pop"
                },
                {
                    "id": "HIPH",
                    "label": "Hip-hop"
                },
                {
                    "id": "KPOP",
                    "label": "K-pop"
                },
                {
                    "id": "TECH",
                    "label": "Techno"
                },
                {
                    "id": "RAP1",
                    "label": "Rap"
                },
                {
                    "id": "JAZZ",
                    "label": "Jazz"
                },
                {
                    "id": "CLAS",
                    "label": "Classical"
                },
                {
                    "id": "MIXE",
                    "label": "Mixed Music"
                }
            ]
        },
        {
            "Drinks": [
                {
                    "id": "ROCK",
                    "label": "Rock"
                },
                {
                    "id": "POP1",
                    "label": "Pop"
                },
                {
                    "id": "HIPH",
                    "label": "Hip-hop"
                },
                {
                    "id": "KPOP",
                    "label": "K-pop"
                },
                {
                    "id": "TECH",
                    "label": "Techno"
                },
                {
                    "id": "RAP1",
                    "label": "Rap"
                },
                {
                    "id": "JAZZ",
                    "label": "Jazz"
                },
                {
                    "id": "CLAS",
                    "label": "Classical"
                },
                {
                    "id": "MIXE",
                    "label": "Mixed Music"
                }
            ]
        }
    ]
}