import React from "react";
import "../../Css/Login_all_mobile/LoginAllMobile.css";


export default function LoadingPage() {
    return (
        <div className='login-main-box'>
            <div className='h1 white'>
                Loading...
            </div>
        </div>
    );
}