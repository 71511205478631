import React, {useState} from "react";
import '../../../Css/main.css';
import SearchIcon1 from "../../img/SearchIcon1.png";

export default function SuggestionElement({suggestion}){
    return(
        <div className="suggestion-search-element">
            <div className="suggestion-search-element-line-1">
                <div className="suggestion-search-element-line-1-icon">
                    <img src={SearchIcon1}/>
                </div>
                <div className="suggestion-search-element-line-1-icon-middle">
                    <div className="suggestion-search-element-line-1-middle-title">
                        {suggestion.name}
                    </div>
                    <div className="suggestion-search-element-line-1-middle-address">
                        {suggestion.address}
                    </div>
                </div>
                <div className="suggestion-search-element-line-1-distance">
                    {suggestion.distance}
                </div>
            </div>
            <div className="suggestion-search-element-line-2">
                <div className='suggestion-search-element-line-2-underline'/>
            </div>
        </div>
    )
}
