import React from "react";
import Filter from "./Filter";


export default function Filters({filters}) {

    function FiltersLine(filers_to_line) {
        return (
            <div className="filters-line">
                <div className="filter-in-line">
                    <Filter text={filers_to_line[0]}/>
                </div>
                <div className="filter-in-line">
                    <Filter text={filers_to_line[1]}/>
                </div>
                <div className="filter-in-line">
                    <Filter text={filers_to_line[2]}/>
                </div>
            </div>
        )
    }

    let filters_content = [];
    for(let i = 0; i < filters.length / 3; i++) {
        filters_content.push(FiltersLine(filters.slice(0, 3)));
        filters.splice(0, 3);
    }
    let filters_last_line = [];
    while(filters.length !== 0) {
        filters_last_line.push(
            <div className="filter-in-line">
                <Filter text={filters[0]}/>
            </div>
        );
        filters.splice(0, 1);
    }
    if(filters_last_line.length > 0) {
        filters_content.push(
            <div className="filters-line">
                {filters_last_line}
            </div>
        )
    }

    return (
        <div className="filters-contener">
            {filters_content}
        </div>
    )
}